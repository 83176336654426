import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'; // Core CSS
import 'primeicons/primeicons.css'; // Icons

/* eslint-disable */
// Importing components
import InputText from 'primevue/inputtext';
// eslint-disable-next-line
import Button from 'primevue/button';
// eslint-disable-next-line
import Card from 'primevue/card';
// eslint-disable-next-line
import Password from 'primevue/password';

const app = createApp(App);

app.use(PrimeVue);
app.use(router);

// Registering components globally
app.component('InputText', InputText);
app.component('Password', Password);
app.component('Button', Button);
app.component('Card', Card);

app.mount('#app');
