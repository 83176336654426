<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style>
/* Global styles */
body, html {
  margin: 0;
  padding: 0;
  background-color: #000; /* Set background color to black */
  color: #fff; /* Optional: change the default text color to white for contrast */
}
</style>
