import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '@/components/LoginPage';
import ServerList from '@/components/ServerList';
import ServerDetails from '@/components/ServerDetails';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/servers',
    name: 'ServerList',
    component: ServerList,
    meta: { requiresAuth: true } // indicate that this route requires authentication
  },
  {
    path: '/servers/:serverId',
    name: 'ServerDetails',
    component: ServerDetails,
    meta: { requiresAuth: true },
    props: true
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Define the function to check login state
async function checkLoginState() {
  try {
    const response = await fetch('/api/check_session', {
      credentials: 'include' // Needed to include HTTP-only cookies in the request
    });
    if (response.ok) {
      return true; // The user is logged in
    } else {
      return false; // The user is not logged in
    }
  } catch (error) {
    console.error('Error checking login state:', error);
    return false;
  }
}

// Use a global beforeEach guard to check for authentication
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isLoggedIn = await checkLoginState();
    if (!isLoggedIn) {
      next({ name: 'Login' });
    } else {
      next(); // proceed to the route
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

export default router;
