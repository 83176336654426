<template>
  <div class="login-container">
    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    <form @submit.prevent="login">
      <input v-model="username" placeholder="Username" type="text" />
      <input v-model="password" placeholder="Password" type="password" />
      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: ''
    };
  },
  methods: {
    async login() {
      try {
        const res = await fetch(`${process.env.VUE_APP_API_BASE_URL}/login`, {
          method: 'POST',
          body: JSON.stringify({
            username: this.username,
            password: this.password
          }),
          headers: { "Content-Type": "application/json" },
        });
        
        const data = await res.json(); // Parse JSON response
        
        if (res.ok) {
          this.errorMessage = ''; // Clear any previous error message
          this.$router.push('/servers'); // Redirect to /servers route upon successful login
        } else {
          // Display an error message from the server, or a generic error if none is provided
          this.errorMessage = data.message || 'Login failed. Please try again.';
          setTimeout(() => {
            this.errorMessage = ''; // Clear the error message after 5 seconds
          }, 5000); // 5000 milliseconds = 5 seconds
        }
      } catch (error) {
        console.error('Login error:', error);
        this.errorMessage = 'There was a problem processing your login.';
        setTimeout(() => {
          this.errorMessage = ''; // Clear the error message after 5 seconds
        }, 5000);
      }
    }
  }
}
</script>
<style scoped>
.login-container {
  max-width: 360px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 8px;
  background: #333;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
input, button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
}
button {
  cursor: pointer;
  background: #007bff;
  color: white;
  border: none;
}
.error-message {
  color: red;
  margin-top: 15px;
}
</style>
