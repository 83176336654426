<template>
    <div>
      <h1>Servers</h1>
      <ul>
        <li v-for="server in servers" :key="server.id" @click="goToServer(server.id)">
          {{ server.name }}
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        servers: [],
      };
    },
    methods: {
      async fetchServers() {
        try {
          const res = await fetch(`${process.env.VUE_APP_API_BASE_URL}/servers`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
          });
          if (!res.ok) {
            throw new Error('Failed to fetch servers');
          }
          const { data } = await res.json();
          // Transform the data to extract relevant server information
          this.servers = data.map((item) => {
            return {
              id: item.attributes.id,
              name: item.attributes.name,
              description: item.attributes.description,
              status: item.attributes.status,
              identifier: item.attributes.identifier,
              // Include other server attributes as needed
            };
          });
          console.log(this.servers);
        } catch (error) {
          console.error('Error fetching servers:', error);
        }
      },
      goToServer(serverId) {
        this.$router.push(`/servers/${serverId}`);
      },
    },
    mounted() {
      this.fetchServers();
    },
  };
  </script>
  
  <style scoped>
  /* Add styles for your server list here */
  </style>
  