<template>
    <div v-if="server && serverResources" class="server-details">
      <h1>Server Details: {{ server.attributes.name }}</h1>
      <p><strong>Description:</strong> {{ server.attributes.description || 'No description provided.' }}</p>
      <p><strong>Server ID:</strong> {{ server.attributes.id }}</p>
      <p><strong>UUID:</strong> {{ server.attributes.uuid }}</p>
      <p><strong>Status:</strong> {{ serverResources.attributes.current_state || 'Unknown' }}</p>
      <p><strong>Environment Variables:</strong></p>
      <ul>
        <li v-for="(value, key) in server.attributes.container.environment" :key="key">
          {{ key }}: {{ value }}
        </li>
      </ul>
      <div class="console">
        <h2>Console Output:</h2>
        <div class="console-output" ref="consoleOutput">
          <div v-for="line in consoleOutput" :key="line" class="console-line">{{ line }}</div>
        </div>
        <!-- If you want to send commands -->
        <input v-model="command" @keyup.enter="sendCommand" placeholder="Enter command...">
      </div>
    </div>
    <div v-else>
      <p>Loading server details...</p>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        server: null,
        serverResources: null,
        ws: null,
        consoleOutput: [],
        command: '',
        serverIdentifier: '',
      };
    },
    async mounted() {
      await this.fetchServerDetails();
      await this.connectToWebSocket();
    },
    methods: {
      async fetchServerDetails() {
        const serverId = this.$route.params.serverId;
        try {
          const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/servers/${serverId}`, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error('Failed to fetch server details');
          }
          const data = await response.json();
          this.server = data; // Assuming the response structure matches your example
          this.serverIdentifier = this.server.attributes.identifier; // Save identifier for global use
          await this.fetchResources(this.server.attributes.identifier); // Use the identifier from the server details response
        } catch (error) {
          console.error('Error fetching server details:', error);
        }
      },
      async fetchResources(identifier) {
        try {
          const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/servers/${identifier}/resources`, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error('Failed to fetch server resources');
          }
          const data = await response.json();
          this.serverResources = data; // Assuming the response structure for resources
        } catch (error) {
          console.error('Error fetching server resources:', error);
        }
      },
      async connectToWebSocket() {
  try {
    // Fetching WebSocket credentials
    const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/servers/${this.serverIdentifier}/websocket`);
    if (!response.ok) {
      throw new Error('Failed to fetch WebSocket credentials');
    }
    const { data } = await response.json();

    // Creating WebSocket connection
    this.ws = new WebSocket(data.socket);

    this.ws.onopen = () => {
      // Authenticate immediately after opening the connection
      this.ws.send(JSON.stringify({
        event: 'auth',
        args: [data.token],
      }));
    };

    this.ws.onmessage = (event) => {
      console.log("WebSocket message received:", event.data);
      const message = JSON.parse(event.data);

      switch (message.event) {
        case 'console output':
          // Handling console output
          this.consoleOutput.push(message.args[0]);
          this.$nextTick(() => {
            const consoleElement = this.$refs.consoleOutput;
            consoleElement.scrollTop = consoleElement.scrollHeight;
          });
          break;
        case 'token expiring':
        case 'token expired':
          // Handling token refresh
          this.refreshWebSocketToken(this.serverIdentifier);
          break;
        default:
          // Handle other events as needed
          console.log("Received message:", message);
      }
    };

    this.ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    this.ws.onclose = (event) => {
      console.log("WebSocket closed:", event);
      // Optionally reconnect or handle closure
    };
  } catch (error) {
    console.error("Failed to connect to WebSocket:", error);
  }
},

    async refreshWebSocketToken(serverIdentifier) {
    try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/servers/${serverIdentifier}/websocket`);
        if (!response.ok) {
        throw new Error('Failed to refresh WebSocket token');
        }
        const { data } = await response.json();

        // Re-authenticate with the new token
        this.ws.send(JSON.stringify({
        event: 'auth',
        args: [data.token],
        }));
    } catch (error) {
        console.error("Failed to refresh WebSocket token:", error);
    }
    },

    sendCommand() {
      // Implementation for sending commands through the WebSocket
      if (this.ws && this.command) {
        this.ws.send(JSON.stringify({
          event: 'send command',
          args: [this.command],
        }));
        this.command = ''; // Clear the command input after sending
      }
    },
  },
  beforeUnmount() {
    // Ensure to close the WebSocket connection when the component unmounts
    if (this.ws) {
      this.ws.close();
    }
  },
};
  </script>
  
  <style scoped>
  .server-details ul {
    list-style-type: none;
    padding: 0;
  }
  .server-details li {
    margin-bottom: 5px;
  }
  </style>
  